import React, { useEffect, useState } from 'react';
import { UserTableData } from '../components/Table/UserTable'
import Axios from '../Axios/Api'

const User: React.FC= ()=> {

  const [userData, setUserData] = useState([]);
  
  const fetchUserData = async () => {
    try {
      const response = await Axios.post('/allUsers?start=0',{});
      const responseData = response.data.result;
      setUserData(responseData.map((data: any, index: number) => ({...data, key: index})));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
   fetchUserData();
  },[]);

  return (
    <div>
      <h1> User Deatil</h1>
      <UserTableData data={userData} fetchUserData={fetchUserData} />
    </div>
  )
}

export default User

