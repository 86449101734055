import React, { FC, Component } from 'react';
import { Layout } from 'antd';
import Sidebar from './Sidebar';
import HeaderPage from './Header';

const LayoutPage = (Comp: FC) => {
  return class RouterCompo extends Component<{}, { isSidebarOpen: boolean }> {
    constructor(props: any) {
      super(props);
      this.state = {
        isSidebarOpen: false
      };
      this.setSidebarOpen = this.setSidebarOpen.bind(this);
    }

    setSidebarOpen() {
      this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
    }

    render() {
      return (
        <Layout>
          <Sidebar isSidebarOpen={this.state.isSidebarOpen}/>
          <Layout>
            <HeaderPage isSidebarOpen={this.state.isSidebarOpen} onToggleSidebar={this.setSidebarOpen} />
            <Layout.Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, }}>
              <Comp />
            </Layout.Content>
          </Layout>
        </Layout>
      )
    }
  }
}

export default LayoutPage;