import Axios from "axios";


var httpClient = Axios.create({
    baseURL: process.env.REACT_APP_BASE_DEV_API,
    headers: {
        'Accept': 'application/json',
            'X-server-appid': 'server',
            'X-server-appkey': 'server',
    },
});
export default httpClient;