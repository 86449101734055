import React, { memo } from 'react'
import { Layout, Menu} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
    UserOutlined,
    PhoneOutlined,
    FilePptOutlined
} from '@ant-design/icons';
const { Sider } = Layout;


const sidebarManuItem: any[] = [
    {
        key: '1',
        icon: <UserOutlined />,
        label: 'Users',
        color:"white",
        route: "/user",
    },
    {
        key: '2',
        icon: <PhoneOutlined />,
        label: 'Callers',
        route: "/caller",
    },
    {
        key: '3',
        icon: <FilePptOutlined />,
        label: 'Premium packages',
        route: "/premium",
    }
]

type SidebarTypes = {
    isSidebarOpen: boolean;
    onToggleSidebar?: any
}

const Sidebar = ({ isSidebarOpen }: SidebarTypes) => {
    const navigate = useNavigate();

    const onMenuClick = ({ key }: { key: string }) => {
        const clickedItem = sidebarManuItem.find((item) => item.key === key);
        navigate(clickedItem.route);
    }

    return (
        <Sider trigger={null} collapsible collapsed={isSidebarOpen}>
            <h1 style={{ padding: "7px", color: "white", textAlign: 'center' }}  > STOCS </h1>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                onClick={onMenuClick}
                items={sidebarManuItem.map(({ key, label, icon}) => ({ key, label, icon }))}   
            />
        </Sider>
    )
}

export default memo(Sidebar);