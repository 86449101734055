import React from 'react';
import { Layout, theme } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
const { Header } = Layout;

type HeaderPageTypes = {
    isSidebarOpen: boolean;
    onToggleSidebar: any
}

const HeaderPage = ({ isSidebarOpen, onToggleSidebar }: HeaderPageTypes) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const onClickToggleSidebar = () => {
        onToggleSidebar(!isSidebarOpen)
    }

    return (
        <>
            <Header style={{ background: colorBgContainer }}>
                {isSidebarOpen ?
                    <MenuUnfoldOutlined className="trigger" onClick={onClickToggleSidebar} /> :
                    <MenuFoldOutlined className="trigger" onClick={onClickToggleSidebar} />}
            </Header>

        </>
    )
}

export default HeaderPage