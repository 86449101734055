import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutPage from "./Layout/Layout";
import Caller from "./Pages/Caller";
import Premium from "./Pages/Premium";
import User from "./Pages/User";

const Routers = () => {
    return (
        <Routes>
            <Route path="/user" element={<User />} />
            <Route path="/caller" element={<Caller />} />
            <Route path="/premium" element={<Premium />} />
        </Routes>
    )
}

export default LayoutPage(Routers);