import React, { useEffect, useState } from 'react';
import { CallerTableData } from '../components/Table/UserTable'
import Axios from '../Axios/Api'

const Caller: React.FC =() => {

  const [callerData, setCallerData] = useState([]);

  const fetchCallerData = async () => {
    try {
      const response = await Axios.post('/allCallers?start=0',{});     
      const responseData = response.data.result;
      setCallerData(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCallerData();
  }, []);

  return (
    <div>
      <h1>Caller Detail</h1>
      <CallerTableData data={callerData} fetchCallerData={fetchCallerData}/>
    </div>
  )
}

export default Caller