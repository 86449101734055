
import React, { useEffect, useState } from 'react';
import { PremiumTableData } from '../components/Table/UserTable'
import Axios from '../Axios/Api';


const Premium: React.FC= ()=> {

  const [premiumData, setPremiumData] = useState([]);
  
  const fetchPremiumData = async () => {
    try {
      const response = await Axios.get('/getRechargeOptions',{});
      const responseData = response.data.result;
      setPremiumData(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    fetchPremiumData();
  },[]);

  return (
    <div>
      <h1> Premium Deatil</h1>
      <PremiumTableData data={premiumData} />
    </div>
  )
}


export default Premium