import React, { useEffect, useState } from 'react';
import './style.css';
import { Table, Switch, Badge, Tooltip, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Resizable } from 'react-resizable';
import type { ResizeCallbackData } from 'react-resizable';
import { CopyOutlined } from '@ant-design/icons';
import Axios from '../../Axios/Api';

interface UserDataType {
    key: React.Key;
    id: number;
    name: string;
    telephone: string,
    deviceToken: string,
    devices: string,
    blocked: boolean,
    verified: boolean,
    referralCode: string,
    upi: string,
    subscribeButton: boolean,
    rechargeButton: boolean,
    otp: string,
    createdAt: string,
    updatedAt: string,
}

interface CallerDataType {
    key: React.Key;
    id: number;
    sebiNumber: string;
    businessName: string;
    profileImage: string;
    verified: boolean;
    aadharUrl: string;
    description: string;
    tradeType: string;
    active: boolean;
    blocked: boolean;
    telephone: string;
    subscriptionId: string;
    rating: number;
    createdAt: string;
    updatedAt: string;
}

interface PremiumDataType {
    packageId: number;
    packageName: string;
    bestSelling: true;
    description: string;
    expiryDateInfo: string;
    packageActive: boolean;
    price: number;
    priceText: string;
}

const ResizableTitle = (
    props: React.HTMLAttributes<any> & {
        onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
        width: number;
    },
) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
};

const renderDataColumn = (text: string) => {
    const maxLength = 10;
    const displayText = text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

    return (
        <div>
            <span>{displayText}</span>
            <Tooltip title="Copy">
                <Button type="link" onClick={() => copyText(text)} icon={<CopyOutlined />} />
            </Tooltip>
        </div>
    );
};
      
interface UserTableDataProps {
    data: Array<UserDataType>;
    fetchUserData: () => void;
}
export const UserTableData: React.FC<UserTableDataProps> = ({ data, fetchUserData }) => {
    const [userData, setUserData] = useState<any>([]);

    useEffect(() => {
      setUserData(data);
    }, [data]);

    const UserBlockUnblock = async (record: UserDataType, checked: boolean) => {
        try {
            await Axios.post(`/blockUnblockUsers?telephone=${encodeURIComponent(record.telephone)}&block=${checked}`);
            await fetchUserData();

        } catch (error) {
            console.error(error);
        }
    };

    const [columns, setColumns] = useState<ColumnsType<UserDataType>>([

        {
            title: 'Id',
            width: 100,
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: "Name",
            width: 120,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Telephone",
            width: 150,
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: 'Devices',
            dataIndex: 'devices',
            key: 'devices',
            width: 150,
        },
        {
            title: 'Device Token',
            dataIndex: 'deviceToken',
            key: 'deviceToken',
            render: renderDataColumn,
            width: 150,
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            width: 150,
            render: (verified: boolean) => verified ? 'true' : 'false',
        },
        {
            title: 'Referral Code',
            dataIndex: 'referralCode',
            key: 'referralCode',
            width: 150,
        },
        {
            title: 'Upi',
            dataIndex: 'upi',
            key: 'upi',
            width: 150,
        },
        {
            title: 'Subscribe Button',
            dataIndex: 'subscribeButton',
            key: 'subscribeButton',
            width: 150,
            render: (subscribeButton: boolean) => subscribeButton ? 'true' : 'false',
        },
        {
            title: 'Recharge Button',
            dataIndex: 'rechargeButton',
            key: 'rechargeButton',
            width: 150,
            render: (rechargeButton: boolean) => rechargeButton ? 'true' : 'false',
        },
        {
            title: 'Otp',
            dataIndex: 'otp',
            key: 'otp',
            width: 150,
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150,
        },
        {
            title: 'UpdatedAt',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 150,
        },
        {
            title: 'Blocked Status',
            dataIndex: 'blocked',
            key: 'blocked',
            width: 150,
            fixed: "right",
            render: (blocked: boolean) => (
                <Badge count={blocked ? "blocked" : "unblocked"} style={{ backgroundColor: blocked ? "red" : "green" }} />
            )
        },
        {
            title: 'Blocked',
            dataIndex: 'blocked',
            key: 'blocked',
            width: 150,
            render: (value: boolean, record: UserDataType) => (
                <Switch
                    checked={value}
                    onChange={(blocked: boolean) => UserBlockUnblock(record, blocked)}
                    checkedChildren={<span>Blocked</span>}
                    unCheckedChildren={<span>Unblocked</span>}
                />
            ),
            fixed: "right"
        },
    ])
    const handleResize: Function =
        (index: number) => (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
            const newColumns = [...columns];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width,
            };
            setColumns(newColumns);
        };

    const mergeColumns: ColumnsType<UserDataType> = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column: ColumnsType<UserDataType>[number]) => ({
            width: column.width,
            onResize: handleResize(index) as React.ReactEventHandler<any>,
        }),
    }));

    const getUserPackage = async (expand: boolean, row: any) => {
      if (expand) {
        const getUserExpand = await Axios.post(`/userPremiumPackages?userId=${row.id}&start=0`);
        setUserData(userData.map((data: any) => ({...data, premiumPackage: row.id === data.id ? getUserExpand?.data?.result : data?.premiumPackage})))
      }
    };
    
    return (
        <React.Fragment>
            <Table
                bordered
                components={{
                    header: {
                        cell: ResizableTitle,
                    },
                }}
                expandable={{
                  expandedRowRender: (record: any) => <>{record?.premiumPackage ? <><div>PackageId: {record?.premiumPackage?.packageId}</div><div>ExpiredAt: {record?.premiumPackage?.expiredAt}</div></> : <div>Not subscribed any package</div>}</>,
                }}
                onExpand={getUserPackage}
                dataSource={userData}
                columns={mergeColumns}
                pagination={{ showSizeChanger: true,  pageSizeOptions : ['10', '20', '30'] }}
                scroll={{
                    x: 1500,
                    y: 300,
                }}
            />
        </React.Fragment>
    );
};

interface CallerTableDataProps {
    data: Array<CallerDataType>;
    fetchCallerData: () => void;
}

export const CallerTableData: React.FC<CallerTableDataProps> = ({ data, fetchCallerData }) => {

    const UserVerified = async (record: CallerDataType, checked: boolean) => {
        try {
            await Axios.post(`/verifyCallers?verified=${checked}&callerId=${record.id}`, '');
            await fetchCallerData();

        } catch (error) {
            console.error(error);
        }
    };
    const UserBlocked = async (record: CallerDataType, blocked: boolean) => {
        try {
            await Axios.post(`/blockUnblockCallers?block=${blocked}&telephone=${encodeURIComponent(record.telephone)}`, '');
            await fetchCallerData();

        } catch (error) {
            console.error(error);
        }
    };

    const [columns, setColumns] = useState<ColumnsType<CallerDataType>>([
        {
            title: 'Id',
            width: 100,
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'SebiNumber',
            dataIndex: 'sebiNumber',
            key: 'sebiNumber',
            width: 150,
        },
        {
            title: "Business Name",
            width: 150,
            dataIndex: 'businessName',
            key: 'businessName',
        },
        {
            title: "Profile Image",
            width: 150,
            dataIndex: 'profileImage',
            key: 'profileImage',
            render: renderDataColumn,
        },
        {
            title: 'Aadhar Url',
            dataIndex: 'aadharUrl',
            key: 'aadharUrl',
            width: 150,
        },
        {
            title: "Description",
            width: 120,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'TradeType',
            dataIndex: 'tradeType',
            key: 'tradeType',
            width: 150,
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width: 150,
            render: (active: boolean) => active ? 'true' : 'false'
        },
        {
            title: 'Blocked',
            dataIndex: 'blocked',
            key: 'blocked',
            width: 150,
            render: (blocked: boolean) => blocked ? 'true' : 'false'
        },
        {
            title: "Telephone",
            width: 150,
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: 'Subscription Id',
            dataIndex: 'subscriptionId',
            key: 'subscriptionId',
            width: 160,
            render: renderDataColumn,
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            width: 150,
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150,
        },
        {
            title: 'UpdatedAt',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 150,
        },
        {
            title: 'Verified Status',
            dataIndex: 'verified',
            key: 'verified',
            width: 150,
            fixed: "right",
            render: (verified: boolean) => (
                <Badge count={verified ? "Verified" : "UnVerified"} style={{ backgroundColor: verified ? "green" : "red" }} />
            )
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            width: 150,
            fixed: "right",
            render: (value: boolean, record: CallerDataType) => (
                <Switch
                    checked={value}
                    onChange={(verified: boolean) => UserVerified(record, verified)}
                    checkedChildren={<span>true</span>}
                    unCheckedChildren={<span>false</span>}
                />
            ),
        },
        {
            title: 'Block Status',
            dataIndex: 'blocked',
            key: 'blocked',
            width: 150,
            fixed: "right",
            render: (blocked: boolean) => (
                <Badge count={blocked ? "Blocked" : "UnBlocked"} style={{ backgroundColor: blocked ? "red" : "green" }} />
            )
        },
        {
            title: 'Blocked',
            dataIndex: 'blocked',
            key: 'blocked',
            width: 150,
            fixed: "right",
            render: (value: boolean, record: CallerDataType) => (
                <Switch
                    checked={value}
                    onChange={(verified: boolean) => UserBlocked(record, verified)}
                    checkedChildren={<span>Blocked</span>}
                    unCheckedChildren={<span>UnBlocked</span>}
                />
            ),
        },
    ])

    const handleResize: Function =
        (index: number) => (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
            const newColumns = [...columns];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width,
            };
            setColumns(newColumns);
        };

    const mergeColumns: ColumnsType<CallerDataType> = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column: ColumnsType<CallerDataType>[number]) => ({
            width: column.width,
            onResize: handleResize(index) as React.ReactEventHandler<any>,
        }),
    }));

    return (
        <Table
            bordered
            components={{
                header: {
                    cell: ResizableTitle,
                },
            }}
            dataSource={data}
            columns={mergeColumns}
            pagination={{ showSizeChanger: true,  pageSizeOptions : ['10', '20', '30'] }}
            scroll={{
                x: 1500,
                y: 300,
            }}
        />
    );
};

interface PremiumTableDataProps {
    data: Array<PremiumDataType>;
}

export const PremiumTableData: React.FC<PremiumTableDataProps> = ({ data }) => {
    const [columns, setColumns] = useState<ColumnsType<PremiumDataType>>([
        {
            title: 'PackageId',
            width: 100,
            dataIndex: 'packageId',
            key: 'packageId',
        },
        {
            title: "PackageName",
            width: 120,
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: "BestSelling",
            width: 120,
            dataIndex: 'bestSelling',
            key: 'bestSelling',
            render: (bestSelling: boolean) => bestSelling ? 'true' : 'false',
        },
        {
            title: "Description",
            width: 150,
            dataIndex: 'description',
            key: 'description',
            render: renderDataColumn,
        },
        {
            title: 'ExpiryDateInfo',
            dataIndex: 'expiryDateInfo',
            key: 'expiryDateInfo',
            width: 150,
            render: renderDataColumn,
        },
        {
            title: 'Package Active',
            dataIndex: 'packageActive',
            key: 'packageActive',
            width: 150,
            render: (packageActive: boolean) => packageActive ? 'true' : 'false',

        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 150,
        },
        {
            title: 'PriceText',
            dataIndex: 'priceText',
            key: 'priceText',
            width: 150,
        },
    ])

    const handleResize: Function =
        (index: number) => (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
            const newColumns = [...columns];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width,
            };
            setColumns(newColumns);
        };

    const mergeColumns: ColumnsType<PremiumDataType> = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column: ColumnsType<PremiumDataType>[number]) => ({
            width: column.width,
            onResize: handleResize(index) as React.ReactEventHandler<any>,
        }),
    }));


    return (
        <React.Fragment>
            <Table
                bordered
                components={{
                    header: {
                        cell: ResizableTitle,
                    },
                }}
                dataSource={data}
                columns={mergeColumns}
                pagination={{ showSizeChanger: true,  pageSizeOptions : ['10', '20', '30'] }}
                scroll={{
                    x: 1500,
                    y: 300,
                }}
            />
        </React.Fragment>
    );
};